
<!--审批记录：审批记录-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">异常提报审批记录</h1>
          </div>
        </div>
        <div class="table-box">
          <div class="control-search">
            <!--添加按钮-->
            <div class="cb-left">
              <span style="font-size:14px">设备名称 </span>
              <el-select
                  v-model="maintainanceForm.deviceId"
                  clearable
                  placeholder="请选择设备"
                  style="width: 200px;margin-left:10px;margin-right: 10px"
              >
                <el-option
                    v-for="item in option1"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
              <el-button
                  class="control-item"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >
                搜索
              </el-button>
            </div>
          </div>
          <div>
            <div class="table-box">
              <el-table
                  :data="maintainanceData"
                  border
                  highlight-current-row
                  style="width: 100%"
              >
                <el-table-column label="设备名称" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column label="异常待机时长" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.actualTime }}
                  </template>
                </el-table-column>
                <el-table-column label="提报原因" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.reason}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="提报人" align="center" width="150">
                  <template slot-scope="scope">
                    <span>{{scope.row.person}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="提报时间" align="center" width="150">
                  <template slot-scope="scope">
                    <span>{{scope.row.date}}</span>
                  </template>
                </el-table-column>

                <el-table-column label="状态" align="center" width="150">
                  <template slot-scope="scope">
                    <div :style="{color:scope.row.state === 0 ? '#EA000E' : scope.row.state === 1 ? '#F7B515' : scope.row.state === 2 ? '#37C239' :'#777474' }" class="reason">{{scope.row.state === 0 ? '未提报' : scope.row.state === 1 ? '未审核' : scope.row.state === 2 ? '已审核' :'超时取消'}}</div>

                  </template>
                </el-table-column>
                <!--操作-->
                <el-table-column label="操作" header-align="center" align="center" width="240">
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="checkReplacement(scope.row.id)">
                      查看
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!--分页-->
            <div class="pagination-box">
              <el-pagination
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="maintainanceForm.count"
                  :total="maintainanceCount"
                  :current-page="maintainanceForm.page"
                  layout="total, sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api';
import {base64toFile} from '@/utils/file';
import moment from "moment";

export default {
  name: "abnormalApproval",
  data() {
    return {
      abnormal: [],// 异常提报
      option1: [],
      maintainanceForm: {
        count: 10,
        page:1,
        deviceId: ''
      },
      abnormalCount: 0,
      maintainanceData: [],// 定期更换
      loading: false,
      maintainanceCount: 0,
      totalCount: 0,
      faultList: [],// 异常提报
      faultForm: {
        page: 1,
        count: 10,
        type: 3
      },
      faultCount: 0,
      activeName: 'first',
      rules: {},
    };
  },
  computed: {},
  created() {
    this.activeName = this.$store.state.abnormalForm.activeName
    this.maintainanceForm.page = this.$store.state.abnormalForm.page
    this.maintainanceForm.count = this.$store.state.abnormalForm.count
    this.maintainanceForm.deviceId = this.$store.state.abnormalForm.deviceId
    this.getList()
    this.getDevice()
    console.log(this.$store.state.userInfo.roleIds)
  },
  mounted() {

  },
  methods: {
    handleSearch() {
      this.maintainanceForm.page = 1
      this.getList()
    },
    getList() {
      API.regularList2(this.maintainanceForm).then(res => {
        console.log(res)
        this.maintainanceData = res.message.data.list
        this.maintainanceCount = res.message.data.totalCount
      })
    },
    getDevice() {
      API.deviceList2().then(res => {
        console.log(res.message.data)
        this.option1.push(...res.message.data)
        this.option1.unshift({name: '全部设备', id: ''})
      })
    },
    dateFmt(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm')
    },
    // 查看
    checkReplacement(id) {
      this.$store.commit('abnormalFormSet', {deviceId:this.maintainanceForm.deviceId,page: this.maintainanceForm.page,count: this.maintainanceForm.count, activeName: this.activeName,});
      this.$router.push({ path: 'abnormalDetail', query: { id: id } })
    },
    back() {
      this.$router.go(-1);
    },
    handleSizeChange(val) {
      this.maintainanceForm.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.maintainanceForm.page = val ;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.table-box {
  position: relative;
}

::v-deep .dialog-footer {
  text-align: right;
}

::v-deep .el-tabs__active-bar {
  height: 2px;
  background-color: #F3A100;
}

.control-item {

}

.control-btn {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -0px;
  z-index: 9999;
}

.control-box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: absolute;
  right: 21px;
  top: 127px;
  z-index: 999;
}

.btn {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  width: 100%;
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  padding: 12px 10px 0px 15px;
  color: #cccfd6;
  cursor: pointer;
}

.person {
  margin-bottom: 20px;
}

.history {
  text-align: right;
}

.record {
  margin-bottom: 0px;

  img {
    width: 15px;
    margin-right: 10px;
  }

  span {
    //display: inline-block;
    text-align: right;
    width: 100%;
    cursor: pointer;
  }
}

.pic-and-video-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    width: 90px;
    height: 90px;
    margin-top: 10px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 0;
    padding: 0;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 5px;
      font-size: 13px;
      color: grey;
    }

    .closeVideo-btn {
      margin-top: 8px;
    }
  }
}

.preview {
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;

  img {
    position: absolute;
    width: 90px;
    height: 90px;
  }

  .van-btton {
    position: absolute;
    right: 0;
    font-size: 20px;
    //top: -15px;
    background: #fff;
    padding: 0;
    color: #f56c6c;
  }
}

.voice {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.video-label {
  width: 100%;
  height: 34px;
  border-radius: 17px;
  background-color: lightgrey;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 13px;
  color: grey;

  .close-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.item {
  padding: 8px 10px;

  img {
    width: 32px;
    margin-right: 10px;
  }
}

.item:hover {
  background-color: #FFFAED;
}

.list-item {
  display: flex;
  justify-content: space-between;
  color: #9F9F9F;
  font-size: 14px;

  .name {
    color: #3D3D3D;
    font-size: 16px;
    margin-bottom: 10px;
  }

  padding: 15px 0;
  border-bottom: 1px solid #F1F1F1;
}

.item-left {
  display: flex;

  img {
    width: 56px;
    margin-right: 15px;
  }
}

.control-search {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 20px;
  //项
  .control-item {
    display: inline-block;

    + .control-item {
      margin-left: 10px;
    }
  }

  //左栏
  .cb-left {
  }

  //右栏
  .cb-right {
  }

  //表单项
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>

